import { gql } from '@apollo/client';
import {
  AccordionItemFragment,
  BigCardMediaItemFragment,
  CardItemFragment,
  CardsFlexibleItemFragment,
  CheckListItemFragment,
  DropDownListItemFragment,
  FaqsQuestionFragment,
  GoogleReviewsItemFragment,
  HorizontalCardListItemFragment,
  MiniCardItemFragment,
  PathologiesItemFragment,
  PersonDetailFragment,
  PriceItemFragment,
  ReputationCardFragment,
  TaskItemFragment,
  DisplaySettingFragment,
  BlogItemFragment,
  LocationItemFragment,
  MapPinFragment
} from './elements';
import { FilePartsFragment, HubspotFormFragment, LinkFragment } from './fields';

export const AccordionFragment = () => gql`
  ${MiniCardItemFragment()}
  ${AccordionItemFragment()}
  ${DisplaySettingFragment()}
  fragment Accordion on ComponentSectionsAccordion {
    __typename
    id
    title
    sectionSubtitle
    hideLineSeparation
    claim
    accordionItem {
      ...AccordionItem
    }
    miniCards {
      ...MiniCardItem
    }
    displaySetting {
      ...DisplaySetting
    }
  }
`;

export const BannerMoreInformationFragment = () => gql`
  ${LinkFragment()}
  ${DisplaySettingFragment()}
  fragment BannerMoreInformation on ComponentSectionsBannerMoreInformation {
    __typename
    id
    sectionTitle
    sectionSubtitle
    hideLineSeparation
    text
    button {
      ...Link
    }
    displaySetting {
      ...DisplaySetting
    }
  }
`;

export const BigCardMediaFragment = () => gql`
  ${BigCardMediaItemFragment()}
  ${PersonDetailFragment()}
  ${LinkFragment()}
  ${DisplaySettingFragment()}
  fragment BigCardMedia on ComponentSectionsBigCardMedia {
    __typename
    id
    title
    sectionSubtitle
    hideLineSeparation
    description
    bigCardMediaItem {
      ...BigCardMediaItem
    }
    persons {
      ...PersonDetail
    }
    button {
      ...Link
    }
    displaySetting {
      ...DisplaySetting
    }
  }
`;

export const CardAndMiniCardGridFragment = () => gql`
  ${CardItemFragment()}
  ${MiniCardItemFragment()}
  ${DisplaySettingFragment()}
  fragment CardAndMiniCardGrid on ComponentSectionsCardAndMiniCardGrid {
    __typename
    id
    title
    sectionSubtitle
    hideLineSeparation
    cardItems {
      ...CardItem
    }
    miniCardItems {
      ...MiniCardItem
    }
    displaySetting {
      ...DisplaySetting
    }
  }
`;

export const CardGridFragment = () => gql`
  ${CardItemFragment()}
  ${LinkFragment()}
  ${DisplaySettingFragment()}
  fragment CardGrid on ComponentSectionsCardGrid {
    __typename
    id
    title
    sectionSubtitle
    showAnchors
    hideLineSeparation
    cardItems {
      ...CardItem
    }
    bigCardLink {
      ...Link
    }
    displaySetting {
      ...DisplaySetting
    }
  }
`;

export const CheckListFragment = () => gql`
  ${MiniCardItemFragment()}
  ${CheckListItemFragment()}
  ${DisplaySettingFragment()}
  fragment CheckList on ComponentSectionsCheckList {
    __typename
    id
    title
    sectionSubtitle
    hideLineSeparation
    claim
    checkListItems {
      ...CheckListItem
    }
    miniCards {
      ...MiniCardItem
    }
    displaySetting {
      ...DisplaySetting
    }
  }
`;

export const ContactFragment = () => gql`
  ${FilePartsFragment()}
  ${LinkFragment()}
  ${DisplaySettingFragment()}
  fragment Contact on ComponentSectionsContact {
    __typename
    id
    sectionTitle
    sectionSubtitle
    hideLineSeparation
    image {
      ...FileParts
    }
    subtitle
    description
    descriptionRichText
    link {
      ...Link
    }
    desktopCta {
      ...Link
    }
    mobileCta {
      ...Link
    }
    invertImagePosition
    displaySetting {
      ...DisplaySetting
    }
  }
`;

export const FaqsModuleFragment = () => gql`
  ${FaqsQuestionFragment()}
  ${LinkFragment()}
  ${DisplaySettingFragment()}
  fragment FaqsModule on ComponentSectionsFaqsModule {
    __typename
    id
    sectionTitle
    sectionSubtitle
    hideLineSeparation
    title
    useCommonFaqs
    specificFaqsQuestions {
      ...FaqsQuestion
    }
    link {
      ...Link
    }
    displaySetting {
      ...DisplaySetting
    }
  }
`;

export const FeeOrientativeFragment = () => gql`
  ${LinkFragment()}
  ${DisplaySettingFragment()}
  fragment FeeOrientative on ComponentSectionsFeeOrientative {
    __typename
    id
    sectionTitle
    sectionSubtitle
    hideLineSeparation
    title
    description
    cta {
      ...Link
    }
    displaySetting {
      ...DisplaySetting
    }
  }
`;

export const GoogleReviewsFragment = () => gql`
  ${DisplaySettingFragment()}
  fragment GoogleReviews on ComponentSectionsGoogleReviews {
    __typename
    id
    title
    sectionSubtitle
    hideLineSeparation
    script
    displaySetting {
      ...DisplaySetting
    }
  }
`;

export const GoogleReviewsSelectedFragment = () => gql`
  ${LinkFragment()}
  ${GoogleReviewsItemFragment()}
  ${DisplaySettingFragment()}
  fragment GoogleReviewsSelected on ComponentSectionsGoogleReviewsSelected {
    __typename
    id
    title
    sectionSubtitle
    hideLineSeparation
    generalRating
    textScore
    textBasedOn
    textTotalReviews
    googleReview1 {
      ...GoogleReview
    }
    googleReview2 {
      ...GoogleReview
    }
    link {
      ...Link
    }
    button {
      ...Link
    }
    displaySetting {
      ...DisplaySetting
    }
  }
`;

export const HighlightedFragment = () => gql`
  ${DisplaySettingFragment()}
  fragment Highlighted on ComponentSectionsHighlighted {
    __typename
    id
    sectionTitle
    sectionSubtitle
    hideLineSeparation
    title
    displaySetting {
      ...DisplaySetting
    }
  }
`;

export const InPageFormFragment = () => gql`
  ${HubspotFormFragment()}
  ${DisplaySettingFragment()}
  fragment InPageForm on ComponentSectionsInPageForm {
    __typename
    id
    form {
      ...HubspotForm
    }
    newVersion
    displaySetting {
      ...DisplaySetting
    }
  }
`;

export const MiniCardCollageFragment = () => gql`
  ${MiniCardItemFragment()}
  ${DisplaySettingFragment()}
  fragment MiniCardCollage on ComponentSectionsMiniCardCollage {
    __typename
    title
    sectionSubtitle
    hideLineSeparation
    id
    text1
    text2
    text3
    miniCard1 {
      ...MiniCardItem
    }
    miniCard2 {
      ...MiniCardItem
    }
    miniCard3 {
      ...MiniCardItem
    }
    miniCard4 {
      ...MiniCardItem
    }
    displaySetting {
      ...DisplaySetting
    }
  }
`;

export const MiniCardGridFragment = () => gql`
  ${MiniCardItemFragment()}
  ${FilePartsFragment()}
  ${DisplaySettingFragment()}
  fragment MiniCardGrid on ComponentSectionsMiniCardGrid {
    __typename
    id
    description
    title
    sectionSubtitle
    hideLineSeparation
    showAll
    showLess
    image {
      ...FileParts
    }
    listOnMobile
    miniCardItems {
      ...MiniCardItem
    }
    displaySetting {
      ...DisplaySetting
    }
  }
`;

export const NextStepsFragment = () => gql`
  ${FilePartsFragment()}
  ${DisplaySettingFragment()}
  fragment NextSteps on ComponentSectionsNextSteps {
    __typename
    id
    sectionTitle
    sectionSubtitle
    hideLineSeparation
    thankTitleWithoutName
    generalDescription
    stepsTitle
    title1
    description1
    title2
    description2
    title3
    description3
    title4
    description4
    imageDesktop {
      ...FileParts
    }
    imageMobile {
      ...FileParts
    }
    displaySetting {
      ...DisplaySetting
    }
  }
`;

export const PageTitleFragment = () => gql`
  ${FilePartsFragment()}
  ${DisplaySettingFragment()}
  fragment PageTitle on ComponentSectionsPageTitle {
    __typename
    id
    sectionTitle
    sectionSubtitle
    hideLineSeparation
    title
    image {
      ...FileParts
    }
    displaySetting {
      ...DisplaySetting
    }
  }
`;

export const PathologiesFragment = () => gql`
  ${LinkFragment()}
  ${PathologiesItemFragment()}
  ${DisplaySettingFragment()}
  fragment Pathologies on ComponentSectionsPathologies {
    __typename
    id
    sectionTitle
    sectionSubtitle
    hideLineSeparation
    title
    description
    claim
    button {
      ...Link
    }
    pathologies {
      ...PathologiesItem
    }
    displaySetting {
      ...DisplaySetting
    }
  }
`;

export const PriceTableFragment = () => gql`
  ${PriceItemFragment()}
  ${LinkFragment()}
  ${DisplaySettingFragment()}
  fragment PriceTable on ComponentSectionsPriceTable {
    __typename
    id
    title
    sectionSubtitle
    hideLineSeparation
    claimTitle
    claimDescription
    priceItem {
      ...PriceItem
    }
    buttonLink {
      ...Link
    }
    displaySetting {
      ...DisplaySetting
    }
  }
`;

export const ReputationGridFragment = () => gql`
  ${ReputationCardFragment()}
  ${DisplaySettingFragment()}
  fragment ReputationGrid on ComponentSectionsReputationGrid {
    __typename
    id
    sectionTitle
    sectionSubtitle
    hideLineSeparation
    cards {
      ...ReputationCard
    }
    previousModuleSeparation
    displaySetting {
      ...DisplaySetting
    }
  }
`;

export const RichTextFragment = () => gql`
  ${FilePartsFragment()}
  ${DisplaySettingFragment()}
  fragment RichText on ComponentSectionsRichText {
    id
    __typename
    title
    sectionTitle
    sectionSubtitle
    hideLineSeparation
    content
    image {
      ...FileParts
    }
    imagePlacement
    displaySetting {
      ...DisplaySetting
    }
  }
`;

export const SeoContentListFragment = () => gql`
${DisplaySettingFragment()}
  fragment SeoContentList on ComponentSectionsSeoContentList {
    __typename
    id
    sectionTitle
    sectionSubtitle
    hideLineSeparation
    seoContentItems {
      title
      column1
      column2
      column3
    }
    displaySetting {
      ...DisplaySetting
    }
  }
`;

export const ServiceExampleFragment = () => gql`
  ${FilePartsFragment()}
  ${PersonDetailFragment()}
  ${PriceItemFragment()}
  ${DisplaySettingFragment()}
  fragment ServiceExample on ComponentSectionsServiceExample {
    __typename
    id
    sectionTitle
    sectionSubtitle
    hideLineSeparation
    image {
      ...FileParts
    }
    persons {
      ...PersonDetail
    }
    price {
      ...PriceItem
    }
    displaySetting {
      ...DisplaySetting
    }
  }
`;

export const SimpleFragment = () => gql`
  ${FilePartsFragment()}
  ${LinkFragment()}
  ${DisplaySettingFragment()}
  fragment Simple on ComponentSectionsSimple {
    __typename
    id
    sectionTitle
    sectionSubtitle
    hideLineSeparation
    image {
      ...FileParts
    }
    subtitle
    description
    descriptionRichText
    link {
      ...Link
    }
    button {
      ...Link
    }
    invertImagePosition
    displaySetting {
      ...DisplaySetting
    }
  }
`;

export const SliderFragment = () => gql`
  ${FilePartsFragment()}
  ${LinkFragment()}
  fragment Slider on Sliders {
    __typename
    id
    sectionTitle
    sectionSubtitle
    hideLineSeparation
    slides {
      ... on ComponentSlidesBasic {
        __typename
        id
        title
        description
        descriptionRichText
        reversed
        link {
          ...Link
        }
        media {
          ...FileParts
        }
      }
    }
  }
`;

export const CardsFlexibleFragment = () => gql`
  ${FilePartsFragment()}
  ${LinkFragment()}
  ${CardsFlexibleItemFragment()}
  ${DisplaySettingFragment()}
  fragment CardsFlexible on ComponentSectionsCardsFlexible {
    __typename
    id
    sectionTitle
    sectionSubtitle
    hideLineSeparation
    cards {
      ...CardsFlexibleItem
    }
    displaySetting {
      ...DisplaySetting
    }
  }
`;

export const CookiebotModificationFragment = () => gql`
${DisplaySettingFragment()}  
fragment CookiebotModification on ComponentSectionsCookiebotModification {
    __typename
    id
    buttonLabel
    displaySetting {
      ...DisplaySetting
    }
  }
`;

export const DropDownListFragment = () => gql`
  ${FilePartsFragment()}
  ${DropDownListItemFragment()}
  ${DisplaySettingFragment()}
  fragment DropDownList on ComponentSectionsDropDownList {
    __typename
    id
    sectionTitle
    sectionSubtitle
    hideLineSeparation
    listTitle
    listType
    listItems {
      ...DropDownListItem
    }
    image {
      ...FileParts
    }
    claim
    displaySetting {
      ...DisplaySetting
    }
    reversePosition
    showImageMobile
  }
`;

export const HorizontalCardListFragment = () => gql`
  ${HorizontalCardListItemFragment()}
  ${DisplaySettingFragment()}
  fragment HorizontalCardList on ComponentSectionsHorizontalCardList {
    __typename
    id
    sectionTitle
    sectionSubtitle
    hideLineSeparation
    cardItems {
      ...HorizontalCardListItem
    }
    displaySetting {
      ...DisplaySetting
    }
  }
`;

export const TaskGridFragment = () => gql`
  ${TaskItemFragment()}
  ${DisplaySettingFragment()}
  fragment TaskGrid on ComponentSectionsTaskGrid {
    __typename
    id
    title
    sectionSubtitle
    hideLineSeparation
    taskItems {
      ...TaskItem
    }
    displaySetting {
      ...DisplaySetting
    }
  }
`;

export const BlogGridFragment = () => gql`
  ${BlogItemFragment()}
  ${LinkFragment()}
  ${DisplaySettingFragment()}
  fragment BlogGrid on ComponentSectionsBlogGrid {
    __typename
    id
    title
    sectionSubtitle
    hideLineSeparation
    blogItems {
      ...BlogItem
    }
    displaySetting {
      ...DisplaySetting
    }
  }
`;

export const LocationGridFragment = () => gql`
  ${LocationItemFragment()}
  ${LinkFragment()}
  ${DisplaySettingFragment()}
  fragment LocationGrid on ComponentSectionsLocationGrid {
    __typename
    id
    title
    anchorId
    mobileImage {
      ...FileParts
    }
    seeAllLink {
    ...Link
    }
    locationItems {
      ...LocationItem
    }
  }
`;

export const LocationsFragment = () => gql`
  ${LocationGridFragment()}
  ${LinkFragment()}
  ${MapPinFragment()}

  fragment Locations on ComponentSectionsLocations {
    __typename
    id
    title
     mapPins {
      ...MapPin
    }
    anchors {
      ...Link
    }
    sliders {
      ...LocationGrid
    }
    displaySetting {
      ...DisplaySetting
    }
  }
`;